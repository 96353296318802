import { apiHeader } from "./BaseUrl";

// Auth - Login
export const loginUser = async (values) => {
  return handleResponse(
    apiHeader.post("/auth/token", {
      email: values.email,
      password: values.password,
      device_token: "string",
      device_type: "web",
    })
  );
};

// Auth - Logout
export const logoutUser = async () => {
  const access_token = localStorage.getItem("token") || {};
  return handleResponse(
    apiHeader.post("/auth/logout", {
      access_token: access_token,
      device_token: "string",
    })
  );
};

// Auth - Forgot Password
export const forgotPassword = async (values) => {
  return handleResponse(
    apiHeader.post("/auth/forgotpwd", {
      email: values.email,
    })
  );
};

//Auth Refresh Token
export const refreshAccessToken = async (refreshToken) => {
  return handleResponse(
    apiHeader.post("/auth/token/refresh", {
      refresh_token: refreshToken,
    })
  );
};

// Auth - Reset Password Api
export const resetPassword = async (values, token) => {
  return handleResponse(
    apiHeader.post("/auth/forgotpwd/reset", {
      email: values.email,
      forgot_pwd_token: token,
      new_password: values.new_password,
      confirm_new_password: values.confirm_password,
    })
  );
};

// Dashboard Card States
export const dashboardStates = async () => {
  return handleResponse(apiHeader.get("/dashboard"));
};

export const cmsGet = async (limit, offset, search, type) => {
  return handleResponse(
    apiHeader.get(`/cms/${type}/?limit=${limit}&offset=${offset}`)
  );
};

export const cmsGetFaqs = async (limit, offset, search, type, id) => {
  return handleResponse(
    apiHeader.get(`/cms/${type}?type_id=${id}&limit=${limit}&offset=${offset}`)
  );
};

export const cmsCreate = async (type, values, id) => {
  let requestBody = {
    title: values.title,
    description: values.description,
  };

  // Add `child: true` if type is 'privacy-policys'
  if (type === "privacy-policys") {
    requestBody.child = true;
  } else if (type === "faqs") {
    requestBody.type_id = id;
  }

  return handleResponse(apiHeader.post(`/cms/${type}`, requestBody));
};

export const FaqsTypeCreate = async (values) => {
  return handleResponse(apiHeader.post(`/cms/faq-types`, values));
};

export const FaqsTypeUpdate = async (values, id) => {
  return handleResponse(apiHeader.put(`/cms/faq-types/${id}`, values));
};

export const cmsUpdate = async (type, values, id) => {
  let requestBody = {
    title: values.title,
    description: values.description,
  };

  // Add `child: true` if type is 'privacy-policys'
  if (type === "privacy-policys") {
    requestBody.child = true;
  }

  return handleResponse(apiHeader.put(`/cms/${type}/${id}`, requestBody));
};

export const cmsUpdatePolicy = async (type, values, id) => {
  return handleResponse(
    apiHeader.put(`/cms/${type}/${id}`, {
      title: "",
      description: values.description,
      child: false,
    })
  );
};

export const cmsDelete = async (type, id) => {
  return handleResponse(apiHeader.delete(`/cms/${type}/${id}`));
};

export const cmsStatusUpdate = async (type, id, statusActive) => {
  return handleResponse(
    apiHeader.patch(`/cms/${type}/${id}/status`, {
      active: statusActive,
    })
  );
};

export const cmsSwap = async (type, new_value, new_id) => {
  return handleResponse(
    apiHeader.patch(`/cms/${type}/sort-order`, {
      new_value: new_value,
      new_id: new_id,
    })
  );
};

export const cmsSwapFaqs = async (type, new_value, new_id, id) => {
  return handleResponse(
    apiHeader.patch(`/cms/${type}/sort-order`, {
      new_value: new_value,
      new_id: new_id,
      type_id: id,
    })
  );
};

// Product Info - Get
export const productInfoGet = async () => {
  return handleResponse(apiHeader.get(`/cms/product-info`));
};

// Product Info - Update
export const productInfoUpdate = async (values, id) => {
  return handleResponse(
    apiHeader.put(`/cms/product-info/${id}`, {
      how_to_order: values?.how_to_order,
      shipping_detail: values?.shipping_details,
      price_match_guarantee: values?.price_match_guarantee,
      return_cancellation: values?.return_cancellations,
    })
  );
};

// ArtWork - Update
export const artWorkGet = async () => {
  return handleResponse(apiHeader.get(`/cms/artwork`));
};

// ArtWork - Update
export const artWorkUpdate = async (values, type, id) => {
  let requestBody;
  if (type == "one") {
    requestBody = {
      section: type,
      title_one: values.title,
      description_one: values.description,
    };
  } else if (type == "two") {
    requestBody = {
      section: type,
      title_two: values.title,
      description_two: values.description,
    };
  } else if (type == "three") {
    requestBody = {
      section: type,
      title_three: values.title,
      description_three: values.description,
    };
  } else {
    requestBody = {
      section: type,
      column_one_title: values?.column_1_title,
      column_one_sub_title: values?.column_1_subtitle,
      column_one_file_extension: values?.editor_1,
      column_one_message: values?.column_1_message,
      column_two_title: values?.column_2_title,
      column_two_sub_title: values?.column_2_subtitle,
      column_two_file_extension: values?.editor_2,
      column_two_message: values?.column_2_message,
      column_three_title: values?.column_3_title,
      column_three_sub_title: values?.column_3_subtitle,
      column_three_file_extension: values?.editor_3,
      column_three_message: values?.column_3_message,
      note: values?.note,
    };
  }

  return handleResponse(apiHeader.put(`cms/artwork/${id}`, requestBody));
};

export const uploadartImage = async (image, id) => {
  const formData = new FormData();
  formData.append("image", image);

  return handleResponse(apiHeader.post(`cms/artwork/${id}/img`, formData));
};

// Settings - User Profile Get Data
export const getProfile = async (values) => {
  return handleResponse(apiHeader.get(`/user`));
};

// Settings - User Profile Change Password
export const changePassword = async (values) => {
  return handleResponse(
    apiHeader.put("/auth/password", {
      email: values.email,
      old_password: values.old_password,
      new_password: values.new_password,
      confirm_password: values.confirm_password,
    })
  );
};

// Settings - User Profile Edit Data
export const editProfile = async (values) => {
  return handleResponse(
    apiHeader.put("/user", {
      first_name: values.first_name,
      last_name: values.last_name,
      slug: values.phone.slug,
      country_code: values.phone.code,
      number: values.phone.number,
    })
  );
};

// Settings - User Profile Edit Image
export const uploadProfileImage = async (image) => {
  const formData = new FormData();
  formData.append("image", image);

  return handleResponse(apiHeader.post(`/user/img`, formData));
};

// Products
export const getAllProducts = async (
  limit,
  offset,
  supplierId,
  styleCode,
  styleName,
  categoryId,
  subCategoryId,
  brand,
  published
) => {
  let queryParams = `limit=${limit}&offset=${offset}&supplier_id=${supplierId}&published=${published}`;
  if (categoryId) queryParams += `&category_id=${categoryId}`;
  if (subCategoryId) queryParams += `&subcategory_id=${subCategoryId}`;
  if (styleCode) queryParams += `&style_code=${styleCode}`;
  if (styleName) queryParams += `&style_name=${styleName}`;
  if (brand) queryParams += `&brand=${brand}`;

  return handleResponse(apiHeader.get(`/products/?${queryParams}`));
};

export const getProductById = async (id) => {
  return handleResponse(apiHeader.get(`/products/${id}`));
};

// Category List
export const getCategoriesList = async () => {
  return handleResponse(apiHeader.get(`/categories/list`));
};

export const productStatusUpdate = async (id, status, supplier_id) => {
  return handleResponse(
    apiHeader.patch(`/products/${id}/status`, { published: !status , supplier_id })
  );
};

export const trendingStatusUpdate = async (id, status, supplier_id) => {
  return handleResponse(
    apiHeader.patch(`/products/${id}/trending`, { trending: !status , supplier_id })
  );
};

export const variant = async (color, size, sku) => {
  let query = `/products/variants/?sku=${sku}`;

  if (color) {
    query += `&color=${color}`;
  }

  if (size) {
    query += `&size=${size}`;
  }

  return handleResponse(apiHeader.get(query));
};


// Categorie
export const categories = async (limit, offset, search) => {
  let queryParams = `limit=${limit}&offset=${offset}`;
  if (search) queryParams += `&search=${search?.replace('&', '%26')}`;

  return handleResponse(apiHeader.get(`/categories/?${queryParams}`));
};

export const getCategoryById = async (id) => {
  return handleResponse(apiHeader.get(`/categories/${id}`));
};

export const createCategory = async (values) => {
  return handleResponse(apiHeader.post("/categories", values));
};

export const uploadCategoryImage = async (id, type, image) => {
  const formData = new FormData();
  formData.append("image", image);

  return handleResponse(
    apiHeader.post(`/categories/${id}/img/${type}`, formData)
  );
};

export const updateCategory = async (id, values) => {
  return handleResponse(apiHeader.put(`/categories/${id}`, values));
};

export const categoriesStatusUpdate = async (id, status) => {
  return handleResponse(
    apiHeader.patch(`/categories/${id}/status`, { active: !status })
  );
};

// Sub Categories
export const subCategories = async (limit, offset, search) => {
  let queryParams = `limit=${limit}&offset=${offset}`;
  if (search) queryParams += `&search=${search}`;

  return handleResponse(apiHeader.get(`/sub-categories/?${queryParams}`));
};

export const getSubCategoryById = async (id) => {
  return handleResponse(apiHeader.get(`/sub-categories/${id}`));
};

export const createSubCategory = async (values) => {
  return handleResponse(apiHeader.post("/sub-categories", values));
};

export const uploadSubCategoryImage = async (id, image) => {
  const formData = new FormData();
  formData.append("image", image);

  return handleResponse(apiHeader.post(`/sub-categories/${id}/img`, formData));
};

export const updateSubCategory = async (id, values) => {
  return handleResponse(apiHeader.put(`/sub-categories/${id}`, values));
};

export const updateSubCategoryStatus = async (id, status) => {
  return handleResponse(
    apiHeader.patch(`/sub-categories/${id}/status`, { active: !status })
  );
};

// Banners
export const banners = async (limit, offset) => {
  return handleResponse(
    apiHeader.get(`/banners/?limit=${limit}&offset=${offset}`)
  );
};

// Banners - Create
export const createBanner = async (values) => {
  return handleResponse(
    apiHeader.post(`/banners`, {
      url: values?.url,
    })
  );
};

// Banners - Update
export const updateBanner = async (id, values) => {
  return handleResponse(
    apiHeader.put(`/banners/${id}`, {
      url: values?.url,
    })
  );
};

// Banners - Update Status
export const updateBannerStatus = async (id, statusActive) => {
  return handleResponse(
    apiHeader.patch(`/banners/${id}/status`, {
      active: statusActive,
    })
  );
};

// Banners - Delete
export const deleteBanner = async (id) => {
  return handleResponse(apiHeader.delete(`/banners/${id}`));
};

// Banners - Swap
export const swapBanners = async (new_value, new_id) => {
  return handleResponse(
    apiHeader.patch(`/banners/sort-order`, {
      new_value: new_value,
      new_id: new_id,
    })
  );
};

// Banners - Upload Image
export const uploadBannerImage = async (id, image) => {
  const formData = new FormData();
  formData.append("image", image);

  return handleResponse(apiHeader.post(`/banners/${id}/img`, formData));
};

// Settings - Get
export const getSettings = async (type) => {
  return handleResponse(apiHeader.get(`/setting/${type}`));
};

// Settings - Update
export const updateSettings = async (type, values) => {
  return handleResponse(apiHeader.put(`/setting/${type}`, values));
};

// Contact Us
export const contactUs = async (limit, offset, search) => {
  let queryParams = `limit=${limit}&offset=${offset}`;
  if (search) queryParams += `&search=${search}`;

  return handleResponse(apiHeader.get(`/contact-us/?${queryParams}`));
};

// Update Customization Tabs
export const updateCustomization = async (type, id, values) => {
  return handleResponse(apiHeader.put(`/cms/${type}/${id}`, values));
};

export const updateAboutUs = async (id, values) => {
  return handleResponse(apiHeader.put(`/cms/about-us/${id}`, values));
};

export const updateAboutUsImg = async (id, type, image) => {
  const formData = new FormData();
  formData.append("image", image);
  formData.append("type", type);

  return handleResponse(apiHeader.post(`/cms/about-us/${id}/img`, formData));
};

// Manage Home - Child List
export const getHomeChid = async (type, limit, offset) => {
  return handleResponse(
    apiHeader.get(
      `/cms/home/child/?limit=${limit}&offset=${offset}&type=${type}`
    )
  );
};

// Manage Home - Update
export const updateHome = async (id, values) => {
  return handleResponse(apiHeader.put(`/cms/home/${id}`, values));
};

// Create Home Child
export const createHomeChild = async (values) => {
  return handleResponse(apiHeader.post(`/cms/home/child`, values));
};

// Update Home Child
export const updateHomeChild = async (id, values) => {
  return handleResponse(apiHeader.put(`/cms/home/child/${id}`, values));
};

// Delete Home Child
export const deleteHomeChild = async (id) => {
  return handleResponse(apiHeader.delete(`/cms/home/child/${id}`));
};

export const updateHomeImg = async (id, image) => {
  const formData = new FormData();
  formData.append("image", image);

  return handleResponse(apiHeader.post(`/cms/home/${id}/img`, formData));
};

// Update Home Child Img
export const updateHomeChildImg = async (id, image) => {
  const formData = new FormData();
  formData.append("image", image);

  return handleResponse(apiHeader.post(`/cms/home/child/${id}/img`, formData));
};

// Delete Home Image
export const deleteHomeImg = async (id, img_name) => {
  return handleResponse(apiHeader.delete(`/cms/home/${id}/img/${img_name}`));
};

// Suppliers
export const getSuppliers = async (limit, offset) => {
  return handleResponse(
    apiHeader.get(`/suppliers/?limit=${limit}&offset=${offset}`)
  );
};

export const getSupplierById = async (id) => {
  return handleResponse(apiHeader.get(`/suppliers/${id}`));
};

export const getSupplierCategories = async (supplier_id) => {
  return handleResponse(apiHeader.get(`/suppliers/${supplier_id}/categories`));
};

export const getSupplierFileLogs = async (supplier_id) => {
  return handleResponse(apiHeader.get(`/suppliers/${supplier_id}/files`));
};

export const postSupplierCategories = async (supplier_id, categories) => {
  return handleResponse(apiHeader.post(`/suppliers/categories/map`, {supplier_id, categories}));
};

export const updateSupplier = async (supplier_id, values) => {
  return handleResponse(apiHeader.put(`/suppliers/${supplier_id}`, values));
};

export const updateSupplierImg = async (supplier_id, image) => {
  const formData = new FormData();
  formData.append("image", image);

  return handleResponse(
    apiHeader.post(`/suppliers/${supplier_id}/img`, formData)
  );
};

export const updateSupplierProducts = async (supplier_id, file) => {
  const formData = new FormData();
  formData.append("supplier_id", supplier_id);
  formData.append("file", file);

  return handleResponse(apiHeader.post(`/suppliers/files/upload`, formData));
};

// Category Linking
export const mapCategories = async (published, file) => {
  const formData = new FormData();
  formData.append("published", published);
  formData.append("file", file);

  return handleResponse(apiHeader.post(`/categories/mapping/upload`, formData));
};

export const getCategoryFileLogs = async () => {
  return handleResponse(apiHeader.get(`/categories/mapping/files`));
};

// Get Sample File
export const getSampleFile = async () => {
  return handleResponse(apiHeader.get(`/categories/mapping/simple-file`));
};

// Reusable Response handler
export const handleResponse = async (apiCall) => {
  let data;
  await apiCall
    .then((res) => {
      data = {
        status: res.status,
        data: res.data,
      };
    })
    .catch((e) => {
      if (e.response) {
        data = {
          ...e.response.data,
          status: e.response.status,
        };
      }
    });
  return data;
};
